import axios from "axios";
import { randomPassword } from "@/utils/globalFunctions";

export default {
  action_create_customer_payments(
    { context, state, commit, rootState },
    payload
  ) {
    // eslint-disable-line no-unused-vars

    let username = payload.username;
    let datapack = payload.datapack;
    let duration = payload.duration;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("action", "createCustomerPaymentXPW2");
    urlencoded.append("duration", duration);
    urlencoded.append("username", username);
    urlencoded.append("datapack", datapack);

    console.log("urlencoded", urlencoded);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch("https://legacy.tizeti.com/geniatechapi/index.php", requestOptions)
      .then((response) =>
        console.log(
          "createCustomerPaymentXPW2 response.text()",
          response.text()
        )
      )
      .then((result) => console.log("createCustomerPaymentXPW2", result))
      .catch((error) => console.log("error", error));
  },
  action_fetch_wallet_history({ context, state, commit, rootState }, payload) {
    // eslint-disable-line no-unused-vars

    const from = payload.from;
    const to = payload.to;
    const reference = payload.reference;
    const url = `${rootState.baseurl}/wallet/fetch/history/${from}/${to}/${reference}`;
    const token = rootState.authentication.token;
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    };

    axios
      .get(url, { headers })
      .then((response) => {
        payload.toast.success("Fetched successfully");
        commit("setWalletHistory", response.data.data);
      })
      .catch((error) => {
        payload.toast.error("An error occurred, please try again");
      });
  },
  action_fetch_topup_wallet_history(
    { context, state, commit, rootState },
    payload
  ) {
    // eslint-disable-line no-unused-vars

    // const from = payload.from
    // const to  =  payload.to
    const phone = payload.phone;
    const url = `${rootState.baseurl}/admin/retailer/list/wallet_recharge?phone=${phone}`;
    // const url = `${rootState.baseurl}/wallet/fetch/history/${from}/${to}/${reference}`
    const token = rootState.authentication.token;
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    };

    axios
      .get(url, { headers })
      .then((response) => {
        payload.toast.success("Fetched successfully");
        //console.log("action_fetch_topup_wallet_history",response.data)
        commit("setWalletTopUpHistory", response.data);
      })
      .catch((error) => {
        payload.toast.error("An error occurred, please try again");
      });
  },
  total_retailer_sales({ context, state, commit, rootState }, payload) {
    // eslint-disable-line no-unused-vars
    const url = rootState.baseurl + "/retailers/sales/customers/count";
    const token = rootState.authentication.token;
    axios
      .get(url, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        commit("setCustomerCount", response.data);
      })
      .catch((error) => {
        payload.toast.error("An error occurred, please try again");
      });
  },

  action_retailer_wallet_balance(
    { context, state, commit, rootState },
    payload
  ) {
    // eslint-disable-line no-unused-vars
    const url = rootState.baseurl + "/wallet/1";
    const token = rootState.authentication.token;
    axios
      .get(url, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        const currency =
          (response.data[0].country == "Nigeria" && "₦ ") ||
          (response.data[0].country == "Ghana" && "GH¢ ") ||
          (response.data[0].country == "CIV" && "XOF");
        commit("setCurrency", currency);
        commit("setWalletBalance", parseInt(response.data[0].balance));
      })
      .catch((error) => {
        payload.toast.error("An error occurred, please try again");
      });
  },
  action_retailer_lifetime_sales_value(
    { context, state, commit, rootState },
    payload
  ) {
    // eslint-disable-line no-unused-vars
    const url = rootState.baseurl + "/retailer/sales/lifetime";
    const token = rootState.authentication.token;
    axios
      .get(url, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        // const currency =  response.data[0].country == "Nigeria" ? "₦ ": "GH¢ "
        let volume = parseInt(response.data.total_sales_nigeria);
        // if(response.data.total_sales_ghana > 0 ){
        //     volume = volume+"| GH¢ "+response.data.total_sales_ghana
        // }

        commit("setSalesVolume", volume);
      })
      .catch((error) => {
        payload.toast.error("An error occurred, please try again");
      });
  },
  action_retailer_total_unread_messages(
    { context, state, commit, rootState },
    payload
  ) {
    // eslint-disable-line no-unused-vars

    const page = payload.page;
    // const url = rootState.baseurl+"/notification"
    const url = `${rootState.baseurl}/notification?page=${page}`;
    const token = rootState.authentication.token;
    axios
      .get(url, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        // const currency =  response.data[0].country == "Nigeria" ? "₦ ": "GH¢ "
        commit("setTotalUnread", response.data.total_unread);
        commit("setTotalNotificationMessages", response.data.messages);
      });
  },

  action_create_notification({ context, state, commit, rootState }, payload) {
    // eslint-disable-line no-unused-vars
    // notification
    const url = rootState.baseurl + `/notification`;
    const token = rootState.authentication.token;
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    };
    const body = {
      subject: payload.subject,
      message: payload.message,
      sender: payload.sender,
      department: payload.department,
      receiver_id: payload.receiver_id,
      channel: payload.channel,
      receiver_category: payload.receiver_category,
    };

    axios
      .post(url, body, { headers })
      .then((response) => {})
      .catch((error) => {
        // payload.toast.error('An error occurred, please try again')
      });
  },
  action_update_unread_messages(
    { context, state, commit, rootState },
    payload
  ) {
    // eslint-disable-line no-unused-vars
    const url = rootState.baseurl + `/notification/${payload.reference}`;
    const token = rootState.authentication.token;
    const body = { status: "Read" };
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    };
    axios
      .put(url, body, { headers })
      .then((response) => {})
      .catch((error) => {
        payload.toast.error("An error occurred, please try again");
      });
  },
  action_view_retailers_locator(
    { context, state, commit, rootState },
    payload
  ) {
    // eslint-disable-line no-unused-vars

    const url = `${rootState.baseurl}/admin/retailer/list/retailer_locator?latitude=${payload.latitude}&longitude=${payload.longitude}`;
    const token = rootState.authentication.token;
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    };
    axios
      .get(url, { headers })
      .then((response) => {
        commit("setComputedRetailers", response.data);
      })
      .catch((error) => {
        payload.toast.error("An error occurred, please try again");
      });
  },
  action_view_retailers({ context, state, commit, rootState }, payload) {
    // eslint-disable-line no-unused-vars

    const url = `${rootState.baseurl}/admin/retailer/list/total_retailers`;
    const token = rootState.authentication.token;
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    };
    axios
      .get(url, { headers })
      .then((response) => {
        commit("setRetailers", response.data);
      })
      .catch((error) => {
        payload.toast.error("An error occurred, please try again");
      });
  },
  action_search_customers({ context, state, commit, rootState }, payload) {
    // eslint-disable-line no-unused-vars

    let value = payload.value;

    if (!isNaN(payload.value)) {
      value = value.replace("234", "0");
    }

    const url = rootState.baseurl + `/customer/${value}`;
    const token = rootState.authentication.token;
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    };
    axios
      .get(url, { headers })
      .then((response) => {
        commit("setCustomers", response.data.data);
      })
      .catch((error) => {
        payload.toast.error("An error occurred, please try again");
      });
  },
  action_delete_customer_account(
    { context, state, commit, rootState },
    payload
  ) {
    // eslint-disable-line no-unused-vars
    const data = payload.data[0];
    const url = `${rootState.baseurl}/user/${data.id}`;
    const token = rootState.authentication.token;
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    };
    axios
      .delete(url, { headers })
      .then((response) => {
        payload.toast.success("Deleted Successfully");
      })
      .catch((error) => {
        payload.toast.error(
          error.message + "An error occurred, Please try again"
        );
      });
  },
  action_add_customer_account({ context, state, commit, rootState }, payload) {
    // eslint-disable-line no-unused-vars
    const data = payload.data[0];
    const url = `${rootState.baseurl}/register/add/customer`;
    const token = rootState.authentication.token;
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    };

    const body = {
      email: data.email,
      password: "admin",
      phone: data.phone,
      name: `${data.prefix}${data.name}`,
      type: "customer",
      country: data.country,
    };

    axios
      .post(url, body, { headers })
      .then((response) => {
        payload.toast.success("Added Successfully");
      })
      .catch((error) => {
        payload.toast.error(
          error.message + "An error occurred, Please try again"
        );
      });
  },
  action_update_retailer_information(
    { context, state, commit, rootState },
    payload
  ) {
    // eslint-disable-line no-unused-vars
    const data = payload.data[0];
    const url = `${rootState.baseurl}/admin/retailer/${data.id}`;
    const token = rootState.authentication.token;
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    };

    // console.log("payload",payload.data)
    //  console.log("data",data)
    const body = {
      user_id: data.id,
      name: data.name,
      email: data.email,
      phone: data.phone,
      business_name: data.business_name,
      address: data.address,
      country: data.country,
      state: data.state,
      city: data.city,
      zone: data.zone,
    };

    axios
      .put(url, body, { headers })
      .then((response) => {
        payload.toast.success(response.message);
        window.location.reload();
      })
      .catch((error) => {
        //console.log('errorrr',error)
        payload.toast.error(
          error.message + "Email and Phone Number must be unique"
        );
      });
  },

  action_retailer_topup_wallet({ context, state, commit, rootState }, payload) {
    // eslint-disable-line no-unused-vars

    const url = `${rootState.baseurl}/wallet/1`;
    const token = rootState.authentication.token;

    const reference = payload.reference;
    const amount = payload.amount;
    const currency = payload.currency;

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    };

    const body = {
      amount: amount,
      payment_ref: reference,
      currency: currency,
      recharge_type: "paystack",
      added_by: "server@tizeti.com",
    };

    axios
      .put(url, body, { headers })
      .then((response) => {
        payload.toast.success("Recharged Successfully");
      })
      .catch((error) => {
        payload.toast.error("An error occurred, please try again");
      });
  },
  action_check_if_retailer_transactions_exists(
    { context, state, commit, rootState },
    payload
  ) {
    // eslint-disable-line no-unused-vars
    // ({ context,state, commit, rootState },payload){

    let today = new Date();
    today =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    const reference = payload.reference;
    const url = `${rootState.baseurl}/wallet/fetch/history/2022-01-01/${today}/${reference}`;
    const token = rootState.authentication.token;
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    };

    const sendGetRequest = async () => {
      try {
        const response = await axios.get(url, { headers });

        if (response.data.data.length > 0) {
          return "stop";
        } else {
          return "start";
        }
      } catch (err) {
        return "stop";
      }
    };

    return sendGetRequest();
  },
  action_retailer_sale_to_customer(
    { context, state, commit, rootState },
    payload
  ) {
    // eslint-disable-line no-unused-vars
    const url = `${rootState.baseurl}/sales`;
    const token = rootState.authentication.token;
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    };

    const body = {
      baseurl: `${rootState.baseurl}`,
      product_uid: payload.product_uid,
      customer_email: payload.customer_email,
      customer_phone: payload.customer_phone,
      datapack: payload.datapack,
      duration: payload.duration,
      amount: payload.amount,
      username: payload.username,
      subject: payload.subject,
      message: payload.message,
      channel: payload.channel,
      receiver_category: payload.receiver_category,
      token: rootState.authentication.token,
      reference: payload.reference,
      receiver_id: payload.receiver_id,
    };

    console.log("payment_body", body);
    axios
      .post(url, body, { headers })
      .then((response) => {
        if (response.data.status) {
          payload.toast.success(response.data.message);

          // send notification here

          const options = {
            method: "POST",
            url: `${rootState.baseurl}/notification`,
            headers: {
              Authorization: `Bearer ${rootState.authentication.token}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            data: {
              subject: "Retailer Sale Notification",
              message: `You just sold a datapack to the customer with the following details \n\n      Customer Phone: ${payload.customer_phone}, \n\n      Customer Email:  ${payload.customer_email}, \n\n      Device ID: ${payload.username}, \n\n      Amount: ${payload.amount}`,
              sender: "admin@tizeti.com",
              department: "Support Team",
              receiver_id: payload.receiver_id,
              channel: "single",
              receiver_category: "retailers",
            },
          };

          axios
            .request(options)
            .then(function (response) {
              console.log(response.data);
            })
            .catch(function (error) {
              console.error(error);
            });

          // end sending notification
        } else {
          payload.toast.error(response.data.message);
        }
      })
      .catch((error) => {
        payload.toast.error("An error occurred, please try again");
      });
  },
  action_retailer_create_account(
    { context, state, commit, rootState },
    payload
  ) {
    // eslint-disable-line no-unused-vars
    const url = `${rootState.baseurl}/manual/register`;
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    const body = {
      email: payload.form[0].email,
      password: randomPassword(6),
      phone: payload.form[0].phone,
      name: payload.form[0].name,
      business_name: payload.form[0].business_name,
      address: payload.form[0].address,
      type: "retailer",
      country: payload.form[0].country,
      state: payload.form[0].state,
      city: payload.form[0].city,
      zone: payload.form[0].zone,
      latitude: payload.form[0].latitude,
      longitude: payload.form[0].longitude,
      balance: payload.form[0].balance,
      status: "Active",
      facebookid: "",
    };
    axios
      .post(url, body, { headers })
      .then((response) => {
        payload.toast.success("Retailer created successfully");
      })
      .catch((error) => {
        payload.toast.error("An error occurred, please try again");
      });
  },
};
