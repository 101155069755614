<template>
  <div class="flex flex-col p-5">
    <p class="text-2xl text-expressgray text-center lg:text-left lg:pl-10 font-semibold font-serif">  Customer Overview</p>

    <div class="grid grid-cols-1  gap-10 mt-5">

        <div class="bg-white text-expressgray p-10 rounded-2xl shadow-xl">
             <p class="text-xl text-expressgray text-center md:text-left font-semibold font-serif">  My Information</p>
              <div class="grid grid-col-1 md:grid-cols-2 text-center md:text-left gap-8 mt-5 mb-5">
                <titlecard title="Email" :description="user.email" colour="text-red-400"/>
                <titlecard title="Phone Number" :description="user.phone" colour="text-expressgray"/>
             </div>
        </div>


      <div v-if="show_paystack_model">
        <addpaystackmodel
            :mac_address=selected_paystack_mac
            @completeTransactionPaystack=completeTransactionPaystack
            @cancelTransactionPaystack=cancelTransactionPaystack
        />
      </div>

      <div v-if="show_change_device_model">
        <adddevicelabel
            :mac_address=selected_mac
            @completeTransaction=completeTransaction
            @cancelTransaction=cancelTransaction
        />
      </div>


        <div class="text-expressgray p-5 ">
          <div  v-if="customers.length>0" class="flex flex-col p-5 ">
            <div v-for="customer, index in customers" :key="index" class=" rounded-4xl shadow-xl p-5">


                  <p class="text-xl text-expressgray text-center md:text-left font-semibold font-serif mt-4 ">  Account Summary</p>
                  <div class="grid text-center md:text-left grid-col-1 md:grid-cols-2 gap-8 mt-5 mb-5">

                    <input type="hidden"  :value="get_customer_expiration_date(customer.mac_address)">

                    <titlecard title="Mac Address" :description="customer.mac_address" colour="text-expressgray"/>
                    <titlecard title="Registered Email" :description="customer.email" colour="text-red-400"/>
                    <titlecard title="Remaining Data Balance" :description="customer.data_balance" colour="text-green-400"/>
                    <titlecard title="Plan Expiration Date" :description="mac_expiration[customer.mac_address]" colour="text-red-400"/>
                    <titlecard title="Total Upload" :description="customer.usage[0].total_upload_mb" colour="text-green-400"/>
                    <titlecard title="Total Download" :description="customer.usage[0].total_download_mb" colour="text-red-400"/>

                    <titlecard title="Device Label" :description="customer.device_label" colour="text-red-500"/>
                    <titlecard title="" description="" colour=""/>

                    <titlecard title="" description="" colour=""/>
                    <titlecard title="" description="" colour=""/>

                    <button class="text-white  bg-navy w-full p-4" @click="change_device_label(customer.mac_address)" > Change Device Label</button>
                     <button class="text-white  bg-navy w-full p-4"  @click="show_paystack_method(customer.mac_address)">Buy Datapack </button>

                  </div>

            </div>

              </div>
                  <div v-else class="flex flex-col p-5">
                    <p class="text-lg text-expressgray">No Device found</p>
                 </div>
             </div>
           </div>









  </div>




</template>

<script>

import TitleContentCard from "@/components/uicomponents/cards/TitleContentCard";
import { mapGetters } from 'vuex'
import {getHumanDate} from "@/utils/globalFunctions"
import add_device_label from "@/components/uicomponents/models/add_device_label";
import add_paystack_model from  "@/components/uicomponents/models/add_paystack_model";
import PaystackPop from '@paystack/inline-js';

export default {
  name: "CustomerMainOverview.vue",
   created() {
      const email = this.user.email
      const phone  = this.user.phone
     console.log('user',this.user)

       // total lifetime sales value
      this.$store.dispatch('customers/action_search_customers',{
         value : phone,
         toast:this.$toast,
         router:this.$router
      });

    },

  components:{
    'titlecard': TitleContentCard,
    'adddevicelabel': add_device_label,
    'addpaystackmodel':add_paystack_model
  },
    computed:{
        ...mapGetters({
          user: 'authentication/getUser',
          customers: 'customers/customers'
        }),
  },
  data(){
    return{
      show_change_device_model: false,
      show_paystack_model: false,
      mac_expiration:{},
      selected_mac: '',
      selected_paystack_mac: ''
    }
  },
       methods: {
         change_device_label(mac_address){
           this.selected_mac = mac_address
           this.show_change_device_model =true
         },
         show_paystack_method(mac_address){
           this.selected_paystack_mac = mac_address
           this.show_paystack_model =true
         },
          getRandomNumberBetween(min,max){
            return Math.floor(Math.random()*(max-min+1)+min);
          },
           payWithPaystack_nigeria(mac, pmtamount,email){
  var datapack = 0 ;
  var duration = 0;
  var product_uid = ""
  const actual_amount  = pmtamount/100 ;
  const custref = `hotspotxwf_nigeria_${this.getRandomNumberBetween(1000,9999)}_${this.getRandomNumberBetween(1000,9999)}_${this.getRandomNumberBetween(1000,9999)}`
  let paystack_mac = mac;




  if(actual_amount === 100){
    datapack =  314572800
    duration = 1
    product_uid = "2c578b1e-9db0-11ec-b812-61d819f2746f"
  }
   else if (actual_amount === 300){
    datapack = 777
    duration = 2
    product_uid = "895798e8-9db1-11ec-b812-61d819f2746h"
  }
 else if (actual_amount === 1000){
               datapack = 777
               duration = 3
               product_uid = "tu5798e8-9db1-11ec-b812-61d819f274ur"
             }
  else if (actual_amount === 1500){
    datapack  = 777
    duration = 7
    product_uid = "995798e8-9db1-11ec-b812-61d819f2746p"
  }
  else if (actual_amount === 3000){
    datapack  = 777
    duration = 14
    product_uid = "895798e8-9db1-11ec-b812-61d819f274mk"
  }
  else if (actual_amount === 6000){
    datapack  = 777
    duration = 30
    product_uid = "895798e8-9db1-11ec-b812-61d819f2795k"
  }


  let metadata = {
    "email": email,
    "duration": duration,
    "datapack": datapack,
    "username": paystack_mac,
    "amount": actual_amount,
    "customer_phone": paystack_mac,
    "phone": email,
    "reference": custref,
    "product_uid": product_uid

  }
  // live nigeeria pk_live_48dd0c126b30e969b1b7eff9f27ded163647f4c6
  // test nigeria pk_test_e1929473ff607af44d68b320871309eba86d377f

  var handler = PaystackPop.setup({
    // key: 'pk_live_48dd0c126b30e969b1b7eff9f27ded163647f4c6', // Replace with your public key
    key: 'pk_live_48dd0c126b30e969b1b7eff9f27ded163647f4c6',
    email:  email.replace(/\s/g, ''),
    metadata:metadata,
    amount: pmtamount, // the amount value is multiplied by 100 to convert to the lowest currency unit
    currency: 'NGN', // Use GHS for Ghana Cedis or USD for US Dollars
    ref: custref, // Replace with a reference you generated
    callback: async function(response) {
      console.log("callback triggered")

    },
    onClose: function() {
      alert('Transaction was not completed, window closed.');
    },
  });
  handler.openIframe();
},
          payWithPaystack_ghana(mac, pmtamount,email){
           var datapack = 0 ;
           var duration = 0;
           var product_uid = ""
           const actual_amount  = pmtamount/100 ;
           const custref = `hotspotxwf_ghana_${this.getRandomNumberBetween(1000,9999)}_${this.getRandomNumberBetween(1000,9999)}_${this.getRandomNumberBetween(1000,9999)}`
         let paystack_mac = mac

            if(actual_amount === 1.5){
              datapack =  314572800
              duration = 1
              product_uid = "295798e8-9db1-11ec-b812-61d819f2746f"
            }

             else if (actual_amount === 5){
             datapack = 777
             duration = 2
             product_uid = "495798e8-9db1-11ec-b812-61d819f27498"
           }
            if (actual_amount === 13){
              datapack = 777
              duration = 3
              product_uid = "565798e8-9db1-11ec-b812-61d819f274yt"
            }

           else if (actual_amount === 25){
             datapack  = 777
             duration = 7
             product_uid = "595798e8-9db1-11ec-b812-61d819f27484"
           }
           else if (actual_amount === 45){
             datapack  = 777
             duration = 14
             product_uid = "695798e8-9db1-11ec-b812-61d819f27485"
           }
           else if (actual_amount === 80){
             datapack = 777
             duration = 30
             product_uid = "895798e8-9db1-11ec-b812-61d819f27488"
           }


           let metadata = {
             "email": email,
             "duration": duration,
             "datapack": datapack,
             "username": paystack_mac,
             "amount": actual_amount,
             "customer_phone": paystack_mac,
             "phone": email,
             "reference": custref,
             "product_uid": product_uid

           }
           // test  ghana pk_test_e3ec372c59321b0d29de291f7c32ab97494d9649
           // livee ghana pk_live_f084164d9488b05581291a92226897e704c0abca

           var handler = PaystackPop.setup({
             key: 'pk_live_f084164d9488b05581291a92226897e704c0abca', // Replace with your public key
             // key: 'pk_test_e3ec372c59321b0d29de291f7c32ab97494d9649',
             email:  email.replace(/\s/g, ''),
             metadata:metadata,
             amount: pmtamount, // the amount value is multiplied by 100 to convert to the lowest currency unit
             currency: 'GHS', // Use GHS for Ghana Cedis or USD for US Dollars
             ref: custref, // Replace with a reference you generated
             callback: async function(response) {



             },
             onClose: function() {
               alert('Transaction was not completed, window closed.');
             },
           });
           handler.openIframe();
         },
            getHumanDate,
          compareDates(date) {
              var UserDate = date;
              var ToDate = new Date();
              if (new Date(UserDate).getTime() <= ToDate.getTime()) {
                   return ['Expired','text-red-400'];
                }
              return ['Active','text-green-400'];
              },
           get_customer_balance(username){

           },
        async get_customer_expiration_date(username){
           var myHeaders = new Headers();
           myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

           var urlencoded = new URLSearchParams();
           urlencoded.append("action", "hotspot_fetch_expiration_date");
           urlencoded.append("username", username);

           var requestOptions = {
             method: 'POST',
             headers: myHeaders,
             body: urlencoded,
             redirect: 'follow'
           };
           await fetch("https://legacy.tizeti.com/geniatechapi/index.php", requestOptions)
               .then(response => response.text())
               .then(result => {
                 result = JSON.parse(result)
                 this.mac_expiration[username] =  result.message
               })
               .catch(error => console.log('error', error));
         },
         completeTransaction(data){
             this.show_change_device_model = false
             const new_label  = data[0].new_label
           this.$store.dispatch('customers/action_change_label',{
             mac_address : data[1],
             new_name : new_label,
             toast:this.$toast,
             router:this.$router
           });
              console.log('completed transaction',data[0].new_label)
         },
         completeTransactionPaystack(data){
           this.show_paystack_model = false
           if(this.user.country === "Nigeria"){
             this.payWithPaystack_nigeria(this.selected_paystack_mac, data[0] , this.user.email)
           }
           else{
             this.payWithPaystack_ghana(this.selected_paystack_mac, data[0] , this.user.email);
           }




         },
         cancelTransactionPaystack(){
           this.show_paystack_model = false
           console.log('cancelTransactionPaystack transaction')
         },
         cancelTransaction(){
              this.show_change_device_model = false
           console.log('cancelTransaction transaction')
         }

        },

}
</script>

<style scoped>

</style>
