<template>
  <div class="w-full">
    <div
      class="nav h-16 flex flex-row border-b-2 border-gray-200 p-2 justify-center sm:justify-between items-center w-full"
    >
      <img
        class="ml-5 h-16 my-2"
        src="https://res.cloudinary.com/dcsensx8u/image/upload/v1644320869/Hotspot_logo_5.png"
        alt="Tizeti Hotspot Logo"
      />
      <!--      <img class="w-32" src="https://res.cloudinary.com/dcsensx8u/image/upload/v1644320869/Hotspot_logo_5.png" alt="Tizeti Hotspot Logo">-->
      <div className="space" id="google_translate_element">
        Select your preferred language
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
};
</script>

<style scoped></style>
