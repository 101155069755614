<template>
  <div class="flex flex-col bg-white w-screen">
    <pheader></pheader>

    <div class="content grid grid-col-1 lg:grid-cols-2 gap-0 h-screen">
      <div
        class="hidden lg:flex items-center justify-center bg-loginbackground bg-no-repeat bg-cover"
      >
        <div class="flex flex-col px-16 space-y-3 text-white">
          <!-- {{  baseurl }} -->
          <h3 class="text-2xl italic">
            Tizeti Hotspot is an innovative Wi-Fi management platform helping
            Wi-Fi operators deliver excellent service and optimize value today
            and as networks scale.
          </h3>
        </div>
      </div>
      <div class="p-8 flex flex-col space-y-12 bg-white">
        <div class="flex flex-col items-center lg:items-start">
          <h2 class="text-express font-semibold">Welcome Back</h2>
          <p class="text-expressgray font-thin">Log in to your account</p>
        </div>

        <div class="text-expressgray">
          <tabs
            :options="{ useUrlFragment: false }"
            @clicked="tabClicked"
            @changed="tabChanged"
            nav-item-class="nav-item"
          >
            <tab name="Login">
              <div class="flex flex-col w-full">
                <form method="post" class="my-5" v-on:submit.prevent>
                  <div class="mt-5">
                    <label for="phone" class="text-sm text-expressgray"
                      >Phone Number</label
                    >
                    <div
                      class="flex relative rounded border-2 border-red hover:border-express items-center justify-between"
                    >
                      <input
                        type="tel"
                        name="emailorphone"
                        @keydown.space.prevent
                        v-model.trim="emailorphone"
                        id="phone"
                        placeholder="Phone Number (234XXXXXXXXXX)"
                        pattern="234[0-9]{10}"
                        required
                        class="p-4 bg-white border-0 focus:border-express focus:text-express fill-green w-full text-express"
                      />
                      <i
                        class="fas fa-check-circle absolute right-5"
                        :class="email_label"
                      ></i>
                    </div>
                  </div>

                  <div class="mt-5">
                    <label for="password" class="text-sm text-expressgray"
                      >Password</label
                    >
                    <div
                      class="flex relative rounded border-2 border-red hover:border-express items-center justify-between"
                    >
                      <input
                        :type="type"
                        name="password"
                        id="password"
                        placeholder=" "
                        required
                        v-model="password"
                        class="p-4 bg-white border-0 focus:border-express w-full text-express"
                      />
                      <div
                        class="absolute right-5 text-express"
                        @click="showPassword"
                      >
                        {{ password_label }}
                      </div>
                    </div>
                  </div>

                  <button
                    class="text-white w-full bg-expressbutton p-3 font-semibold mt-5 capitalize tracking-wide h-14"
                    type="button"
                    @click="login"
                  >
                    LOGIN
                  </button>
                </form>

                <div
                  class="h-14 w-full flex flex-row items-center justify-center lg:justify-end"
                >
                  <!-- <h2> <input type="checkbox" class="rounded-full" name="remember" value="remember" id="remember"><label for="remember"> </label> </h2> -->
                  <!--                    <h2>Recover Password</h2>-->
                  <router-link
                    to="/recover"
                    class="hover:cursor-pointer hover:underline text-center lg:text-right"
                    >Recover Password
                  </router-link>
                </div>
              </div>
            </tab>
          </tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { mapGetters } from "vuex";

export default {
  created() {
    //clear all token and logs
    this.$store.dispatch("authentication/logout");
  },
  name: "Login",
  setup() {
    return { v$: useVuelidate() };
  },
  computed: {
    ...mapGetters({ baseurl: "authentication/baseurl" }),
  },
  data() {
    return {
      emailorphone: "",
      password: "",
      email_label: "text-expressgray",
      options: ["Nigeria", "Ghana", "CIV"],
      password_label: "SHOW",
      type: "password",
      fullname_signup: "",
      email_signup: "",
      phone_signup: "",
      country_signup: "Nigeria",
      password_signup: "",
      confirm_password: "",
      disabled_signup: true,
    };
  },

  validations: function () {
    let confirm_password = this.confirm_password;
    return {
      fullname_signup: { required }, // Matches this.firstName
      phone_signup: { required }, // Matches this.lastName
      email: { required, email }, // Matches this.contact.email
      password_signup: { required, confirm_password },
    };
  },
  email: function () {
    if (this.email.length > 0) {
      this.email_label = "text-express";
    } else {
      this.email_label = "text-expressgray";
    }
  },
  methods: {
    showPassword() {
      if (this.type === "password") {
        this.type = "text";
        this.password_label = "HIDE";
      } else {
        this.type = "password";
        this.password_label = "SHOW";
      }
    },

    // Update the Google Translate language based on the selected country before login

    //     if (this.emailorphone.length === 11) {
    //     // Assuming 11-digit numbers belong to Nigeria (country code +234)
    //     this.emailorphone = `234${this.emailorphone.slice(-10)}`;
    // // Example: 08189706132 -> 2348189706132
    //     } else if (this.emailorphone.length === 10) {
    // // Assuming 10-digit numbers belong to Ghana (country code +233)
    //     this.emailorphone = `233${this.emailorphone.slice(-9)}`;
    // // Example: 818970613 -> 233818970613
    //     } else if (this.emailorphone.length > 11) {
    // // Assuming 9-digit numbers belong to Ivory Coast (country code +225)
    //     this.emailorphone = `225${this.emailorphone.slice(-9)}`;
    // // Example: 818970613 -> 225818970613
    //     }
    // if(this.emailorphone.match(/234[0-9]{10}/)  || this.emailorphone.match(/233[0-9]{9}/) || this.emailorphone.match(/225[0-9]{9}/) ){

    // }
    // else{
    //   this.$toast.error( 'Incorrect Phone Number Pattern')
    //  }

    // if( (this.emailorphone == "08163470928" ) && this.password=="admin" ){
    //   this.$router.push('/accounts')
    // }
    // else{
    //   this.$toast.error(
    //       'Incorrect login credentials')
    // }
    loadGoogleTranslateScript(callback) {
      if (window.google && window.google.translate) {
        callback();
      } else {
        console.log("first login");
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src =
          "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
        script.onload = callback;
        document.head.appendChild(script);
      }
    },
    setTranslateLanguage(language) {
      this.loadGoogleTranslateScript(() => {
        if (window.google && window.google.translate) {
          const select = document.querySelector("select.goog-te-combo");
          if (select) {
            console.log("second login");
            select.value = language;
            select.dispatchEvent(new Event("change"));
          }
        }
      });
    },
    login() {
      if (this.emailorphone.startsWith('225')) {
        // Add a longer delay and some debugging

        this.setTranslateLanguage("fr");

        this.$store.dispatch("authentication/login", {
          phone: this.emailorphone,
          password: this.password,
          toast: this.$toast,
          router: this.$router,
        });
        //clear selection
      } else {
        this.setTranslateLanguage("en");
        this.$store.dispatch("authentication/login", {
          phone: this.emailorphone,
          password: this.password,
          toast: this.$toast,
          router: this.$router,
        });
      }

      // Run the main function
    },
    validateEmail(emailAdress) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailAdress);
    },
  },
  mounted() {
    console.log("Initializing Google Translate");
    this.loadGoogleTranslateScript(() => {
      console.log("Google Translate script loaded");
      window.googleTranslateElementInit = () => {
        console.log("Initializing Google Translate Element");
        new window.google.translate.TranslateElement(
          {
            pageLanguage: "en",
            includedLanguages: "en,fr",
            layout:
              window.google.translate.TranslateElement.InlineLayout.HORIZONTAL,
          },
          "google_translate_element"
        );
        console.log("Google Translate Element initialized");
      };
      window.googleTranslateElementInit();
    });
  },
};
</script>

<style scoped>
ul {
  /*flex justify-around space-x-5 border-2 border-black mt-5 p-5*/
  display: flex;
  justify-content: space-around;
  border: 2px solid black;
  margin: 15px;
  padding: 15px;
}

input[type="email"] {
  background-color: white !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: #3c9fa9;
}
</style>
