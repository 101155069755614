import axios from 'axios'

export default {

   async action_get_customer_payment_history({ context,state, commit, rootState },payload){ // eslint-disable-line no-unused-vars
        // customer/sales/history/2347030839921
        const phone = payload.phone
        const url = `${rootState.baseurl}/customer/sales/history/${phone}`
        const token = rootState.authentication.token
        const headers =  {
            'Content-Type': 'application/json',
             'Accept': 'application/json',
             'Authorization' : 'Bearer '+ token
            }
            try {

                const response = await axios.get(url,{headers} )

                if (response.status === 200) {
                    commit('setPaymentHistory', response.data.data)
                }
                
            } catch (error) {
                console.log(error);
            }
           

    },

        action_customer_reported_issues({ context,state, commit, rootState },payload) { // eslint-disable-line no-unused-vars
            const url = rootState.baseurl + `/customerissue?page=${payload.page}`
            const token = rootState.authentication.token
            const headers = {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
            axios.get(url, {headers}).then(response => {
               // console.log('setCustomerIssues', response.data.data)
                commit('setCustomerIssues', response.data.data)
            }).catch((error) => {
              //  console.log("error", error)
                payload.toast.error('An error occurred, please try again')
            })

        },
        action_customer_list({ context,state, commit, rootState },payload) { // eslint-disable-line no-unused-vars
            const url = rootState.baseurl + `/admin/customer/list/total_customers`
            const token = rootState.authentication.token
            const headers = {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }

            const body = {
                status:"status",
                page_no: payload.page_no,
                total_records_per_page:  payload.perPage
            }
            axios.post(url,body, {headers}).then(response => {
                //console.log('setCustomer_List', response.data.data)
                commit('setCustomerList', response.data.data)
            }).catch((error) => {
             //   console.log("error", error)
                payload.toast.error('An error occurred, please try again')
            })

        },
        action_search_customers ({ context,state, commit, rootState },payload) { // eslint-disable-line no-unused-vars

        const url = rootState.baseurl + `/customer/${payload.value}`
            console.log('url',url)
        const token = rootState.authentication.token
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        }
        axios.get(url, {headers}).then(response => {
            console.log('setCustomers', response.data.data)
            commit('setCustomers', response.data.data)
        }).catch((error) => {
           // console.log("error", error)
            payload.toast.error('An error occurred, please try again')
        })

    },
    action_change_label ({ context,state, commit, rootState },payload) { // eslint-disable-line no-unused-vars

        const url = rootState.legacyurl
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("action", "hotspot_add_device_label");
        urlencoded.append("username", payload.mac_address);
        urlencoded.append("new_name", payload.new_name);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch(url, requestOptions)
            .then(response => response.text())
            .then(result => {
                result  = JSON.parse(result)
                if(result.status){
                    payload.toast.success(result.message);
                    window.location.reload();
                }else{
                    console.log(result, payload)
                    payload.toast.error("Operation failed, Please try again");
                }

            })
            .catch(error => console.log('error', error));


    },

    action_customers_report_issue ({ context,state, commit, rootState },payload){ // eslint-disable-line no-unused-vars
        // const url = rootState.baseurl+`/customer/${payload.value}`
        const url = rootState.baseurl+`/customerissue`
        const token = rootState.authentication.token


        const body = {
            message: payload.message,
            category: payload.category,
            customer_email:  payload.customer_email
        }

        const headers =  {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization' : 'Bearer '+ token
        }
        axios.post(url,body,{headers} ).then(response => {
            //console.log("action_customers_report_issue", response.data)
            if(response.data.status){
                payload.toast.success("Created Successfully")
            }else{
                payload.toast.error('An error occurred, please try again___')
            }
        }).catch((error)=>{
           // console.log("error", error)
            payload.toast.error('An error occurred, please try again')
        })

    }
}
