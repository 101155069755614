<template>
  <div class="min-h-screen mb-7">
    <div
      v-if="country == 'Nigeria'"
      class="bg-white rounded-2xl shadow-lg w-full p-8 flex flex-col min-h-screen object-contain"
    >
      <p class="font-bold md:text-2xl text-xl text-center mb-5 mt-5 font-serif">
        Top-Up Retailer Wallet
      </p>
      <div class="grid grid-col-1 xl:grid-cols-2 gap-8 w-full">
        <div
          class="border-expressgray w-full border-2 p-3 space-y-2 hover:border-express rounded-lg transform transition duration-500 hover:scale-105 flex flex-col items-center md:items-start"
        >
          <p class="text-2xl text-center mb-3">Pay via Paystack</p>
          <p>Current Balance - {{ currency }} {{ wallet_balance }}</p>
          <div class="flex flex-col lg:flex-row text-center">
            <p>
              Enter Amount ({{ currency }}) -
              <input
                type="number"
                min="10000"
                class="rounded p-2 border-4 ml-3"
                placeholder="Amount (Minimun 10,000"
                v-model="amount"
              />
            </p>
          </div>
          <p>Retailer Bonus - {{ bonus_amount }}</p>

          <button
            :class="{
              disabled: amount < 10000,
              'hover:bg-expressgray': (amount) => 10000,
            }"
            class="bg-express p-2 text-white rounded-2xl w-44"
            @click="payWithPaystack"
          >
            Pay
          </button>
        </div>
        <div
          class="h-full border-expressgray border-2 p-3 hover:border-express rounded-lg transform transition duration-500 hover:scale-105 flex flex-col justify-between items-center md:items-start"
        >
          <p class="text-2xl text-center mb-1">Pay By Bank Transfer</p>
          <div class="flex space-x-3 items-center">
            <p>Bank Name: Zenith Bank</p>
            <img
              src="https://res.cloudinary.com/dcsensx8u/image/upload/v1657194890/Screenshot_2022-07-07_at_12.54.36_PM.png"
              alt="Zenith bank logo"
              class="ml-3 w-24 object-contain"
            />
          </div>

          <p>Accont Name: Tizeti Network Limited</p>
          <p>Account Number: 1013184381</p>
          <p class="text-xs text-red-600">Minimum Payment: 10,000 NGN</p>
          <p class="text-xs mt-5">
            Kindly send your retailer name, number and proof of payment to
            <span class="font-bold italic">hotspot@wifi.com.ng</span>
          </p>
        </div>
      </div>
    </div>

    <!--      CIV-->
    <div
      v-else-if="country == 'CIV'"
      class="bg-white rounded-2xl shadow-lg w-full p-8 flex flex-col min-h-screen"
    >
      <p class="font-bold md:text-2xl text-xl text-center mb-5 mt-5 font-serif">
        Top-Up Retailer Wallet
      </p>

      <div class="payment-info">
        <h2 class="heading">Payment Information</h2>

        <p>
          You can pay for your subscription via <strong>WAVE</strong> by
          following the steps below:
        </p>

        <ol class="payment-steps">
          <li>
            <span class="step-number">1</span> <strong>GO TO PAYMENT</strong>
          </li>
          <li>
            <span class="step-number">2</span><p> <strong>SEARCH</strong>
            for (TIZETI CIV NETWORK)
          </p>
          </li>
          <li>
            <span class="step-number">3</span>
            <p>
            <strong>PAY YOUR REQUIRED AMOUNT IN FCFA</strong>
          </p>
          </li>
          <li>
            <span class="step-number">4</span>
            <p >
            <strong>TAKE A SCREENSHOT</strong>
            of the payment
          </p>
          </li>
          <li>
            <span class="step-number">5</span>
            <p >
            <strong>SEND THE SCREENSHOT VIA WHATSAPP</strong>
            to 0712759775 followed by your account name
          </p>
          </li>

        </ol>

        <p class="highlight">
          You can also pay at <strong>Tizeti Network CIV</strong>, Ecobank
          Plateau Headquarters branch, account number
          <strong>121671510001</strong>.
        </p>
        <p>
          <em>Note:</em> Payments made via direct deposit/bank transfers require
          a 24-hour processing time for confirmation.
        </p>

        <p>
          <strong
            >Please ensure that the payment is made before the due date to avoid
            any service interruptions.</strong
          >
        </p>

        <p>
          If you have any questions, complaints, or need further information,
          please contact us using one of the following methods:
        </p>

        <ul class="contact-info">
          <li>
            <strong>Email:</strong>
            <a href="mailto:internet@civwifi.com">internet@civwifi.com</a>
          </li>
          <li><strong>Call:</strong> +2250712759775</li>
          <li>
            <strong>Social Media:</strong>
            <a href="https://facebook.com/civwifi" target="_blank">@civwifi</a>
            (Facebook),
            <a href="https://twitter.com/civwifi" target="_blank">@civwifi</a>
            (Twitter),
            <a href="https://instagram.com/civwifi" target="_blank">@civwifi</a>
            (Instagram)
          </li>
          <li>
            <strong>Website:</strong>
            <a href="https://www.civwifi.com" target="_blank"
              >www.civwifi.com</a
            >
          </li>
        </ul>

        <p>
          Thank you for choosing <strong>Tizeti Network CIV (CIVWIFI)</strong>.
        </p>
      </div>
    </div>
    <!--      Ghana-->
    <div
      v-else
      class="bg-white rounded-2xl shadow-lg w-full p-8 flex flex-col min-h-screen"
    >
      <p class="font-bold md:text-2xl text-xl text-center mb-5 mt-5 font-serif">
        Top-Up Retailer Wallet
      </p>
      <div class="grid grid-col-1 xl:grid-cols-2 gap-8 w-full">
        <div
          class="border-expressgray w-full border-2 p-3 space-y-2 hover:border-express rounded-lg transform transition duration-500 hover:scale-105 flex flex-col items-center md:items-start"
        >
          <p class="text-2xl text-center mb-3">Pay via Paystack</p>
          <p>Current Balance - GH₵ {{ wallet_balance }}</p>
          <div class="flex flex-col lg:flex-row text-center">
            <p>
              Enter Amount (GH₵) -
              <input
                type="number"
                min="100"
                class="rounded p-2 border-4 ml-3"
                placeholder="Amount (Minimun 180 GH₵ "
                v-model="amount_ghana"
              />
            </p>
          </div>
          <p>Retailer Bonus - {{ bonus_amount }}</p>

          <button
            :class="{
              disabled: amount < 100,
              'hover:bg-expressgray': (amount) => 100,
            }"
            class="bg-express p-2 text-white rounded-2xl m-5"
            @click="payWithPaystackGhana"
          >
            Pay
          </button>
        </div>
        <div
          class="h-full border-expressgray border-2 p-3 hover:border-express rounded-lg transform transition duration-500 hover:scale-105 flex flex-col justify-between items-center md:items-start"
        >
          <p class="text-2xl text-center mb-1">Pay By Bank Transfer</p>
          <div class="flex space-x-3 items-center">
            <p>Bank Name: Access Bank</p>
            <img
              src="https://res.cloudinary.com/dcsensx8u/image/upload/v1644311815/access-bank-logo.png"
              alt="Access bank logo"
              class="ml-3 w-24 object-contain"
            />
          </div>

          <p>Account Name: TIZETI NETWORK GHANA LIMITED</p>
          <p>Account Number: 0555113933</p>
          <p class="text-xs text-red-600">Minimum Payment: 100 GH₵</p>
          <p class="text-xs mt-5">
            Kindly send your retailer name, number and proof of payment to
            <span class="font-bold italic">hotspot@wifi.com.ng</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PaystackPop from "@paystack/inline-js";
import { mapGetters } from "vuex";

export default {
  name: "TopUp",
  created() {
    // console.log("wallet_balance",this.wallet_balance)
    // console.log("currency",this.currency)
    console.log("getUser", this.getUser.country);
    this.country = this.getUser.country;

    // console.log("paystack_keys",this.paystack_keys)
  },
  data() {
    return {
      // email: 'tundeadejire@tizeti.com',
      amount: 0,
      amount_ghana: 0,
      amount_civ: 0,
      bonus_amount: null,
      country: null,
    };
  },
  watch: {
    amount: function (val) {
      console.log("amount new", val);
      if (val < 15000) {
        this.bonus_amount = Math.round(0.1 * val);
      } else if (val < 100000) {
        this.bonus_amount = Math.round(0.2 * val);
      } else {
        this.bonus_amount = Math.round(0.3 * val);
      }
    },
    amount_ghana: function (val) {
      if (val < 271) {
        this.bonus_amount = Math.round(0.1 * val);
      } else if (val < 1809) {
        this.bonus_amount = Math.round(0.2 * val);
      } else {
        this.bonus_amount = Math.round(0.3 * val);
      }
    },
    amount_civ: function (val) {
      if (val < 271) {
        this.bonus_amount = Math.round(0.1 * val);
      } else if (val < 1809) {
        this.bonus_amount = Math.round(0.2 * val);
      } else {
        this.bonus_amount = Math.round(0.3 * val);
      }
    },
  },
  computed: {
    ...mapGetters({
      wallet_balance: "retailers/wallet_balance",
      currency: "retailers/currency",
      getUser: "authentication/getUser",
      paystack_keys: "authentication/paystack_keys",
    }),
    randomNum() {
      return (
        Math.floor(Math.random() * (999999999 - 10000000000)) + 10000000000
      ); // You can remove the Math.floor if you don't want it to be an integer
    },
  },
  methods: {
    check_if_paystack_reference_exists: async function (reference) {
      //  this.loadingmessage="Please wait, Searching for Customer Details"
      //   this.isloading = true
      //console.log("search_customer_live", "triggered")

      return this.$store.dispatch(
        "retailers/action_check_if_retailer_transactions_exists",
        {
          reference: reference,
          toast: this.$toast,
          router: this.$router,
        }
      );

      // if (this.isNumeric(this.customer)){
      //   this.customer = "+234"+this.customer.slice(-10)
      // }

      // if(this.customers.length<=0){
      //   this.$toast.error('Customer not found')
      // }

      // this.isloading = false
    },
    payWithPaystackGhana() {
      this.amount = this.amount_ghana;

      if (this.amount >= 180) {
        //console.log("paywithpaystack triggered")
        let ref = `HOTSPOT_RETAILER_${this.getUser.phone}_${this.randomNum}_${this.randomNum}`;
        console.log("ref", ref);
        let paystack_keys = "pk_live_f084164d9488b05581291a92226897e704c0abca";
        let vm = this;
        let store = this.$store;
        let router = this.$router;
        let toast = this.$toast;

        // console.log("action_check_if_retailer_transactions_exists", results)
        var handler = PaystackPop.setup({
          key: paystack_keys,
          email: this.getUser.email,
          amount: this.amount * 100,
          currency: "GHS",
          ref: ref, // Replace with a reference you generated
          callback: function (response) {
            if (response.message == "Approved") {
              //  console.log("paystack response ", response)

              let result = vm.check_if_paystack_reference_exists(ref);
              result.then((data) => {
                // console.log('data',data)
                if (data == "start") {
                  console.log("start recharge transaction");

                  let total_amount = vm.bonus_amount + vm.amount;
                  let total_ref = ref;
                  store.dispatch("retailers/action_retailer_topup_wallet", {
                    reference: total_ref,
                    amount: total_amount,
                    currency: "GH",
                    toast: toast,
                    router: router,
                  });

                  toast.success("Recharge Approved");
                  // router.push(`/${ref}/success`)
                  router.replace("/retailer/overview");
                }
              });
            }
          },
          onClose: function () {
            alert("Transaction was not completed, window closed.");
          },
        });
        handler.openIframe();
      } else {
        this.$toast.error("Minimum amount 180 GHC");
      }
    },
    payWithPaystackCIV() {
      this.amount = this.amount_civ;

      if (this.amount >= 180) {
        //console.log("paywithpaystack triggered")
        let ref = `HOTSPOT_RETAILER_${this.getUser.phone}_${this.randomNum}_${this.randomNum}`;
        console.log("ref", ref);
        let paystack_keys = "pk_live_16bc3a0595de705ea3f7163b6f3f5f444b62f4e0";
        let vm = this;
        let store = this.$store;
        let router = this.$router;
        let toast = this.$toast;

        // console.log("action_check_if_retailer_transactions_exists", results)
        var handler = PaystackPop.setup({
          key: paystack_keys,
          email: this.getUser.email,
          amount: this.amount * 100,
          currency: "XOF",
          language: "fr",
          ref: ref, // Replace with a reference you generated
          callback: function (response) {
            if (response.message == "Approved") {
              //  console.log("paystack response ", response)

              let result = vm.check_if_paystack_reference_exists(ref);
              result.then((data) => {
                // console.log('data',data)
                if (data == "start") {
                  console.log("start recharge transaction");

                  let total_amount = vm.bonus_amount + vm.amount;
                  let total_ref = ref;
                  store.dispatch("retailers/action_retailer_topup_wallet", {
                    reference: total_ref,
                    amount: total_amount,
                    currency: "XOF",
                    toast: toast,
                    router: router,
                  });

                  toast.success("Recharge Approved");
                  // router.push(`/${ref}/success`)
                  router.replace("/retailer/overview");
                }
              });
            }
          },
          onClose: function () {
            alert("Transaction was not completed, window closed.");
          },
        });
        handler.openIframe();
      } else {
        this.$toast.error("Minimum amount 180 XOF");
      }
    },
    payWithPaystack() {
      // action_check_if_retailer_transactions_exists

      if (this.amount >= 10000) {
        console.log("paywithpaystack triggered");
        let ref = `HOTSPOT_RETAILER_${this.getUser.phone}_${this.randomNum}_${this.randomNum}`;
        console.log("ref", ref);
        let paystack_keys = this.paystack_keys;
        let vm = this;
        let store = this.$store;
        let router = this.$router;
        let toast = this.$toast;

        // console.log("action_check_if_retailer_transactions_exists", results)
        var handler = PaystackPop.setup({
          key: paystack_keys,
          email: this.getUser.email,
          amount: this.amount * 100,
          currency: "NGN",

          ref: ref, // Replace with a reference you generated
          callback: function (response) {
            if (response.message == "Approved") {
              //  console.log("paystack response ", response)

              let result = vm.check_if_paystack_reference_exists(ref);
              result.then((data) => {
                // console.log('data',data)
                if (data == "start") {
                  console.log("start recharge transaction");

                  let total_amount = vm.bonus_amount + vm.amount;
                  let total_ref = ref;
                  store.dispatch("retailers/action_retailer_topup_wallet", {
                    reference: total_ref,
                    amount: total_amount,
                    currency: "NGN",
                    toast: toast,
                    router: router,
                  });

                  toast.success("Recharge Approved");
                  // router.push(`/${ref}/success`)
                  router.replace("/retailer/overview");
                }
              });
            }
          },
          onClose: function () {
            alert("Transaction was not completed, window closed.");
          },
        });
        handler.openIframe();
      } else {
        this.$toast.error("Minimum amount 10,000 NGN");
      }
    },
  },
};
</script>

<style scoped>
.payment-info {
  background-color: #f0f4f8;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: auto;
  margin: 0 auto;
  font-family: "Roboto", sans-serif;
}

.heading {
  font-size: 28px;
  font-weight: 700;
  color: #2c3e50;
  margin-bottom: 20px;
  text-align: center;
}

.payment-info p,
.payment-info li {
  font-size: 18px;
  line-height: 1.7;
  color: #34495e;
  margin-bottom: 15px;
}

.payment-steps {
  padding-left: 0;
  list-style: none;
  margin-bottom: 25px;
}

.payment-steps li {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.step-number {
  background-color: #29bca6;
  color: white;
  border-radius: 50%;
  padding: 8px 15px;
  font-weight: bold;
  margin-right: 15px;
}

.contact-info {
  list-style: none;
  padding-left: 0;
  margin-top: 20px;
}

.contact-info li {
  margin-bottom: 10px;
}

.contact-info a {
  color: #29bca6;
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact-info a:hover {
  color: #2980b9;
  text-decoration: underline;
}

a {
  color: #29bca6;
}

a:hover {
  text-decoration: underline;
}

.highlight {
  background-color: #e7f3fc;
  padding: 10px 15px;
  border-left: 4px solid #3498db;
  margin-bottom: 15px;
  font-size: 18px;
}

.signature {
  font-size: 20px;
  font-weight: 500;
  color: #2c3e50;
  margin-top: 20px;
  margin-bottom: 5px;
  text-align: center;
}
</style>
