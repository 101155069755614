<template>
  <div class="min-h-screen">
    <div>
      <h2
        class="text-xl text-center lg:pl-10 uppercase lg:text-left mt-5 font-semibold font-serif"
      >
        Customer Purchase History
      </h2>
    </div>
    <div
      class="flex relative rounded border w-4/5 border-expressgray justify-between items-center mt-7 mx-auto"
    >
      <input
        type="text"
        class="w-full px-4 py-1 border-0 ml-2 outline-none"
        v-model.trim="customer"
        placeholder="Search using phone number"
        @keyup.enter="search_history"
      />
      <i
        class="fas fa-search ml-1 p-2 text-lg hover:text-express hover:scale-100 cursor-pointer"
        @click="search_history"
      ></i>
    </div>

    <div class="mt-5 mx-5">
      <CustomerPaymentHistoryTable
        :dummydata="payment_history"
        :isLoading="isLoading"
      />
    </div>
  </div>
</template>

<script setup>
import CustomerPaymentHistoryTable from "@/components/uicomponents/tables/CustomerPaymentHistoryTable";
import { computed, ref } from "vue";
import { useToast } from "vue-toast-notification";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const customer = ref(null);
const isLoading = ref(false);

const store = useStore();
const $toast = useToast();
const $router = useRouter();

const payment_history = computed(
  () => store.getters["customers/payment_history"]
);

async function search_history() {
  if (!checkNumber(customer.value).status) {
    $toast.info("Please provide valid phone number", {
      position: "top-right",
    });
    return;
  }

  isLoading.value = true;

  store.commit("customers/setPaymentHistoryEmpty");

  await store.dispatch("customers/action_get_customer_payment_history", {
    phone: checkNumber(customer.value).digit,
    toast: $toast,
    router: $router,
  });

  isLoading.value = false;
}

function checkNumber(num) {
  console.log(num);
  const phone = "234" + num.slice(-10);
  if (!isNaN(num) && num.length >= 10) {
    return {
      status: true,
      digit: Number(phone),
    };
  }
  return {
    status: false,
  };
}
</script>

<style></style>
