<template>
  <div class="h-full">
    <router-view />
  </div>
</template>

<style>
html,
body {
  background-color: #f9f9f9;
  font-family: "Roboto", sans-serif;
}

a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none;
  border-color: white;
}

.tabs-component-tabs {
  display: flex;
  justify-content: space-around;
}

.nav-item {
  width: 100%;
  text-align: center;
  text-decoration: none;
  padding: 10px;
}
.is-active {
  border-bottom: 2px solid #3c9fa9;
  /*padding: 10px;*/
  text-decoration: none;
  color: #3c9fa9;
}
a > .is-active {
  border-color: white;
}

.boxhead a {
  color: #ffffff;
  text-decoration: none;
}
</style>
