<template>
  <div class="min-h-screen flex flex-col justify-between bg-mainsection">
    <!-- Main content area -->
    <div class="flex-grow">
      <div class="grid grid-cols-4 gap-1">
        <div class="col-span-4 lg:col-span-3">
          <div class="flex justify-between items-center border-b-2 border-gray-200">
            <aheader></aheader>
            <button
              type="button"
              class="block lg:hidden pr-8"
              @click="sidebarOpened = true"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>

          <div class="bg-mainsection mb-10">
            <router-view v-slot="slotProps">
              <transition name="route" mode="out-in">
                <component :is="slotProps.Component"></component>
              </transition>
            </router-view>
          </div>
        </div>

        <amenumobile @close="sidebarOpened = false" :sidebaropened="sidebarOpened" />
        <div class="hidden lg:block col-span-1 overflow-hidden">
          <amenu></amenu>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <div class="footer flex flex-col h-14 bg-white text-expressgray border-t-2 rounded shadow-lg items-center justify-center p-3">
      <p class="text-xs">
        &#169; {{ new Date().getFullYear() }} Powered by
        <a href="https://tizeti.com" target="_blank">Tizeti Network Limited</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "RetailerOverView",

  data() {
    return {
      sidebarOpened: false,
    };
  },
};
</script>

<style scoped>
.route-enter-from {
  opacity: 0;
  transform: translateX(100px);
}
.route-enter-active {
  transition: all 0.3s ease-out;
}
.route-leave-to {
  opacity: 0;
  transform: translateX(-100px);
}
.route-leave-active {
  transition: all 0.3s ease-in;
}
</style>
