<template>
  <div class="flex flex-col rounded w-full pt-4 px-1 min-h-screen space-y-6">
    <div>
      <h2
        class="text-xl text-center lg:pl-10 uppercase lg:text-left mt-5 font-semibold font-serif"
      >
        Customer Usage History
      </h2>
    </div>
    <form
      class="flex flex-col flex-wrap xl:space-x-5 space-x-0 xl:flex-row space-y-4 xl:items-center mt-3"
    >
      <div class="rounded border w-4/5 lg:w-3/5 border-expressgray">
        <input
          type="text"
          class="w-full px-4 py-1 border-0 rounded outline-none"
          v-model.trim="mac"
          placeholder="Search using mac id"
        />
      </div>

      <div class="flex space-x-2">
        <div class="flex space-x-1 items-center">
          <label class="mx-1 text-sm whitespace-nowrap" for="start"
            >Start Date:</label
          >
          <input
            type="date"
            v-model="startDate"
            id="start"
            class="border-2 rounded p-1"
          />
        </div>

        <div class="flex space-x-1 items-center">
          <label class="mx-1 text-sm whitespace-nowrap" for="end"
            >End Date:</label
          >
          <input
            type="date"
            v-model="endDate"
            id="end"
            class="border-2 rounded p-1"
          />
        </div>
      </div>

      <input
        type="submit"
        value="Search"
        @click.prevent="getUsageHistory"
        class="h-10 w-40 rounded-md bg-green-600 shadow text-white hover:bg-green-500 cursor-pointer"
      />
    </form>

    <div
      v-if="!isLoading"
      class="flex flex-col rounded shadow-lg bg-white w-full mt-10 text-expressgray mb-5 h-full"
    >
      <div class="overflow-x-scroll">
        <table class="w-full">
          <tr class="border-b h-14 border-ndigo-100 text-center bg-indigo-50">
            <th class="p-6">Mac</th>
            <th class="p-6">Upload (mb)</th>
            <th class="p-6">Download (mb)</th>
            <th class="p-6">date</th>
          </tr>
          <tbody v-if="customer_usage_List.length > 0">
            <tr
              class="border-b h-14 border-gray-600 text-center"
              v-for="(item, index) in customer_usage_List"
              :key="index"
            >
              <td class="p-6">{{ item.username }}</td>
              <td class="p-6">{{ item.upload_mb }}</td>
              <td class="p-6 capitalize">{{ item.download_mb }}</td>
              <td class="p-6">{{ item.startDate }}</td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr>
              <p class="whitespace-nowrap font-semibold text-base ml-2">
                No data available
              </p>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="mt-5 mx-5" v-else>
      <loading message="Loading, please wait"></loading>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useToast } from "vue-toast-notification";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { isValidMACAddress } from "@/utils/globalFunctions.js";

const store = useStore();
const $toast = useToast();
const $router = useRouter();

const mac = ref(null);
const startDate = ref(new Date().toISOString().split("T")[0]);
const endDate = ref(new Date().toISOString().split("T")[0]);
const isLoading = ref(false);

const customer_usage_List = computed(
  () => store.getters["admins/customer_usage_list"]
);

async function getUsageHistory() {
  if (!isValidMACAddress(mac.value)) {
    $toast.error("Please provide valid Mac ID", {
      position: "top-right",
      duration: 4500,
    });
    return;
  }

  isLoading.value = true;
  await store.dispatch("admins/get_customer_usage_history", {
    mac: mac.value,
    startdate: startDate.value,
    enddate: endDate.value,
    toast: $toast,
    router: $router,
  });
  isLoading.value = false;
}
</script>
