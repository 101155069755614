<template>

    <form class="p-4 bg-white"  @submit.prevent="submit">

        <div class="flex justify-between items-center">
            <div class="text-xl text-gray-600 my-10">
                Signup Hotspot User
            </div>

            <select name="country" id="country" v-model="country" class="border rounded border-gray-400 h-10 focus:outline-none">
                <option value="nigeria">Nigeria</option>
                <option value="ghana">Ghana</option>
                <option value="civ">Cote D'Ivoire</option>
            </select>
        </div>



        <div class="grid grid-cols-1 md:grid-cols-2 gap-y-10 gap-x-4">

            <div class="flex flex-col">
            <label for="macid">Mac ID</label>
            <input 
                type="text" 
                name="macid" 
                id="macid" 
                v-model="macid"
                class="p-2 rounded border border-black w-full" 
                placeholder="Enter Mac ID"
                required
                >
        </div>



        <div class="flex flex-col">
            <label for="email">Name</label>
            <input 
                type="text" 
                name="name" 
                id="name" 
                 v-model="name"
                class="p-2 rounded border border-black w-full" 
                placeholder="Enter Name"
                required
                >
        </div>


        <div class="flex flex-col">
            <label for="email">Email</label>
            <input
             type="email" 
             name="email" 
             id="email" 
             v-model="email"
             class="p-2 rounded border border-black w-full" 
             placeholder="Enter Email"
             required
             >
        </div>




        <div class="flex flex-col">
            <label for="email">Phone Number</label>
            <input 
                type="tel" 
                name="phone" 
                id="phone" 
                v-model="phone"
                class="p-2 rounded border border-black w-full" 
                placeholder="Enter Phone Number"
                required
                >
        </div>
        </div>


        <div class="w-full flex justify-center">
            <button 
                class="bg-green-500 hover:bg-green-400 w-1/2 p-4 my-10 rounded-md text-white text-center flex justify-center"
                :disabled="buttonDisabled"
                >

                <svg v-if="isLoading"  class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>


                <span v-else>
                    Signup
                </span>
               
            </button>
        </div>
  
    </form>
</template>

<script setup>
import { onMounted, watch } from "vue";
import { computed, ref } from "vue";
import { useToast } from "vue-toast-notification";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { isValidMACAddress } from "@/utils/globalFunctions.js";

const store = useStore();
const $toast = useToast();
const $router = useRouter();

const macid = ref("")
const name = ref("")
const email = ref("")
const phone = ref("")
const country = ref("nigeria")
const isLoading = ref(false);


const buttonDisabled = computed(() => {
  return (
    !name.value || !email.value || !phone.value || !macid.value
  );
});

const submit = async () => {
    if (!isValidMACAddress(macid.value)) {
    $toast.error("Please provide valid Mac ID", {
      position: "top-right",
      duration: 4500,
    });
    return;
  }

    isLoading.value = true    

    const res = await store.dispatch("admins/hotspotSignup", {
        macid: macid.value,
        name: name.value,
        email: email.value,
        phone: phone.value,
        country: country.value,
        toast: $toast,
        router: $router,
    });

   isLoading.value = false;

    if (res) {
        macid.value = "";
        name.value = "";
        email.value = "";
        phone.value = "";
    }

}
</script>