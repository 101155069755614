<template>
    <div class="w-full bg-white shadow-lg px-5 pt-8 pb-10">
      <h1
        class="text-xl text-center lg:pl-10 capitalize lg:text-left mt-5 font-semibold font-serif"
      >
        Retailer to Retailer Data Transfer
      </h1>
  
      <form action="" class="mt-8 w-full flex flex-col items-center">
        <div class="grid md:grid-cols-2 xl:grid-cols-3 gap-5 w-full">
          <div class="flex flex-col md:flex-row md:items-center md:space-x-4">
            <label for="from" class="font-semibold mb-2 sm:mb-0">From:</label>
  
            <input
              type="text"
              name="fromPhone"
              id="fromPhone"
              v-model="fromPhone"
              placeholder="Phone number"
              class="outline-none bg-white h-10 shadow-md border rounded-lg w-full px-3"
            />
          </div>
  
          <div class="flex flex-col md:flex-row md:items-center md:space-x-4">
            <label for="to" class="font-semibold mb-2 sm:mb-0">To:</label>
  
            <input
              type="text"
              name="toPhone"
              id="toPhone"
              v-model="toPhone"
              placeholder="Phone number"
              class="outline-none bg-white h-10 shadow-md border rounded-lg w-full px-3"
            />
          </div>
     

          <div class="flex flex-col md:flex-row md:items-center md:space-x-4">
            <label for="from" class="font-semibold mb-2 sm:mb-0">Amount:</label>
  
            <input
              type="number"
              name="amount"
              id="amount"
              v-model="creditAmount"
              placeholder="amount"
              class="outline-none bg-white h-10 shadow-md border rounded-lg w-full px-3"
            />
          </div>
        </div>
  
        <input
          type="submit"
          name="transfer"
          id="transfer"
          @click.prevent="submit"
          value="Transfer"
          :disabled="!buttonDisabled"
          class="mt-11 rounded-lg saturate-50 text-white h-11 w-56 mx-auto"
          :class="
            !buttonDisabled
              ? 'bg-green-400 cursor-not-allowed'
              : 'bg-green-600 cursor-pointer'
          "
        />
      </form>
  
      <div v-if="isTransferLoading" class="mt-5 mx-3">
        <loading message=""></loading>
      </div>
    </div>
  </template>
  
  <script setup>
  import { onMounted, watch } from "vue";
  import { computed, ref } from "vue";
  import { useToast } from "vue-toast-notification";
  import { useStore } from "vuex";
  import { useRouter } from "vue-router";
  import { isValidMACAddress } from "@/utils/globalFunctions.js";
  
  const store = useStore();
  const $toast = useToast();
  const $router = useRouter();
  
  const isLoading = ref(false);
  const isTransferLoading = ref(false);
  const fromPhone = ref("");
  const toPhone = ref("");
  const creditAmount = ref(0);
//   const plans = computed(() => store.getters["products/products"]);
  
  const buttonDisabled = computed(() => {
    console.log(creditAmount.value);
    
    return (
        fromPhone.value.length >= 10 &&
        toPhone.value.length >= 10 &&
        creditAmount.value > 0
    );
  });

//   onMounted(async () => {
//     await fetchPlans(currency.value);
//   });
  
//   watch(currency, async (newCurrency) => {
//     selected_plan.value = [];
//     await fetchPlans(newCurrency);
//   });
  
  const submit = async () => {

  
    if (fromPhone.value.length < 10) {
      $toast.error("Check phone number validity", {
        position: "top-right",
        duration: 4500,
      });
      return;
    }

    if (toPhone.value.length < 10) {
      $toast.error("Check phone number validity", {
        position: "top-right",
        duration: 4500,
      });
      return;
    }

    if (creditAmount.value.length <= 0) {
      $toast.error("Check amount validity", {
        position: "top-right",
        duration: 4500,
      });
      return;
    }

  
    isTransferLoading.value = true;
  
    await store.dispatch("admins/transferCreditBetweenRetailers", {
      fromPhone: fromPhone.value,
      toPhone: toPhone.value,
      amount: creditAmount.value,
      toast: $toast,
      router: $router,
    });
    isTransferLoading.value = false;
  
    toPhone.value = "";
  };
  </script>
  