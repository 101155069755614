<template>
  <div class="bg-white m-4 p-4">
    <h1 class="mt-2 mb-2 text-2xl">Hotspot Products Breakdown</h1>
    <div class="flex justify-between mt-4">
      <div class="flex p-4 border-2">
        <h3 class="mx-4">Start Date</h3>
        <input type="date" placeholder="YYYY-MM-DD" v-model="from" />
      </div>

      <div class="flex p-4 border-2">
        <h3 class="mx-4">End Date</h3>
        <input type="date" placeholder="YYYY-MM-DD" v-model="to" />
      </div>

      <div class="flex p-4 border-2">
        <select v-model="plan_type">
          <option value="unlimited">Unlimited Data Plan</option>
          <option value="capped">Capped Data Plan</option>
        </select>
      </div>

      <div class="flex p-4 border-2">
        <select v-model="currency">
          <option value="NGN">Naira</option>
          <option value="GHC">Ghana Cedis</option>
          <option value="XOF">XOF</option>
        </select>
      </div>

      <button
        class="flex p-4 border-2 bg-green-500 hover:bg-green-700 text-white"
        @click="hotspot_sales_break_down"
      >
        Search
      </button>
    </div>
    <div class="flex p-4 mt-5 mb-5 w-full">
      <loading v-if="loading"></loading>

      <div v-if="sales_data.length > 0" class="w-full">
        <table class="border-2 p-4 text-center w-full">
          <thead class="border-2 p-4">
            <tr>
              <th>S/N</th>
              <th>Product Name</th>
              <th>Total Amount (Sold) in {{ currency }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="border-2 p-4"
              v-for="(item, i) in sales_data"
              :key="item.product_name"
            >
              <td class="p-4">{{ i + 1 }}</td>
              <td class="p-4">{{ item.product_name }}</td>
              <td class="p-4">{{ item.amount.toLocaleString() }}</td>
            </tr>
            <tr>
              <td class="p-4"></td>
              <td class="p-4">Total</td>
              <td class="p-4">
                {{ total_sales_data.toLocaleString() }} {{ currency }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>No record(s) found</div>
    </div>

    <h1 class="mt-40 mb-2 text-2xl">Hotspot Active/Expired Breakdown</h1>
    <div class="flex justify-between mt-4">
      <div class="flex p-4 border-2">
        <h3 class="mx-4">Country</h3>
        <select v-model="hotspot_country">
          <option value="Nigeria">Nigeria</option>
          <option value="Ghana">Ghana</option>
          <option value="CIV">Cote d'Ivoire</option>
        </select>
      </div>

      <div class="flex p-4 border-2">
        <h3 class="mx-4">Category</h3>
        <select v-model="hotspot_category">
          <option value="active">Active</option>
          <option value="inactive">Expired</option>
        </select>
      </div>

      <div class="flex p-4 border-2">
        <select v-model="hotspot_plan_type">
          <option value="unlimited">Unlimited Data Plan</option>
          <option value="capped">Capped Data Plan</option>
        </select>
      </div>

      <button
        class="flex p-4 border-2 bg-green-500 hover:bg-green-700 text-white"
        @click="hotspot_expiration_break_down"
      >
        Search
      </button>
    </div>

    <div class="flex p-4 mt-5 mb-5">
      <loading v-if="hotspot_loading"></loading>
      <div v-if="hotspot_users.length > 0" class="w-full mt-10">
        <!--        Download users-->
        <table class="border-2 p-4 text-center w-full">
          <thead class="border-2 p-4">
            <tr>
              <th>Plan Type</th>
              <th>Country</th>
              <th>Category</th>
              <th>Number of Users</th>
            </tr>
          </thead>
          <tbody>
            <tr class="border-2 p-4">
              <td class="p-4 capitalize">{{ hotspot_data.plan_type }}</td>
              <td class="p-4 capitalize">{{ hotspot_data.country }}</td>
              <td class="p-4 capitalize">{{ hotspot_data.category }}</td>
              <td class="p-4 capitalize">{{ hotspot_data.count }}</td>
            </tr>
          </tbody>
        </table>

        <button
          class="w-full p-4 bg-green-500 mt-10 text-white"
          @click="
            downloadCSVFromJson(
              `hotspot_${hotspot_data.plan_type}_${hotspot_data.country}_${hotspot_data.category}_users.csv`,
              hotspot_users
            )
          "
        >
          Download Users Report
        </button>
      </div>
      <div v-else>No record(s) found</div>
    </div>

    <!--    Top Users-->
    <h1 class="mt-40 mb-2 text-2xl">
      Hotspot Top/Low Users Data-Consumption Breakdown
    </h1>
    <div class="flex justify-between mt-4">
      <div class="flex p-4 border-2">
        <h3 class="mx-4">Country</h3>
        <select v-model="hotspot_topusers_country">
          <option value="Nigeria">Nigeria</option>
          <option value="Ghana">Ghana</option>
          <option value="CIV">Cote d'Ivoire</option>
        </select>
      </div>

      <div class="flex p-4 border-2">
        <h3 class="mx-4">Order</h3>
        <select v-model="hotspot_topusers_order">
          <option value="desc">High - Low Data Users</option>
          <option value="asc">Low - High Data Users</option>
        </select>
      </div>

      <div class="flex p-4 border-2">
        <h3 class="mx-4">Start Date</h3>
        <input
          type="date"
          placeholder="YYYY-MM-DD"
          v-model="hotspot_topusers_from"
        />
      </div>

      <div class="flex p-4 border-2">
        <h3 class="mx-4">Start Date</h3>
        <input
          type="date"
          placeholder="YYYY-MM-DD"
          v-model="hotspot_topusers_to"
        />
      </div>

      <button
        class="flex p-4 border-2 bg-green-500 hover:bg-green-700 text-white"
        @click="hotspot_topusers_break_down"
      >
        Search
      </button>
    </div>

    <div class="flex p-4 mt-5 mb-5">
      <loading v-if="hotspot_topusers_loading"></loading>
      <div v-if="hotspot_topusers.length > 0" class="w-full mt-10">
        <!--        Download users-->
        <button
          class="w-full p-4 bg-green-500 mt-10 text-white"
          @click="
            downloadCSVFromJson(
              `hotspot_${hotspot_topusers_country}_${hotspot_topusers_from}_${hotspot_topusers_to}_${hotspot_topusers_order}_top_datausage_users.csv`,
              hotspot_topusers
            )
          "
        >
          Export {{ hotspot_topusers_total_users }} Users Report
        </button>
      </div>
      <div v-else>No record(s) found</div>
    </div>

    <!--    End of Top Users-->

    <!--    Retailer Sales BreakDown -->

    <h1 class="mt-40 mb-2 text-2xl">
      Hotspot Top/Low Retailer Sales Breakdown
    </h1>
    <div class="flex justify-between mt-4">
      <div class="flex p-4 border-2">
        <h3 class="mx-4">Country</h3>
        <select v-model="hotspot_retailer_sales_country">
          <option value="Nigeria">Nigeria</option>
          <option value="Ghana">Ghana</option>
          <option value="CIV">Cote d'Ivoire</option>
        </select>
      </div>

      <div class="flex p-4 border-2">
        <h3 class="mx-4">Order</h3>
        <select v-model="hotspot_retailer_sales_order">
          <option value="desc">High-Low Selling Retailers</option>
          <option value="asc">Low-High Selling Retailers</option>
        </select>
      </div>

      <div class="flex p-4 border-2">
        <h3 class="mx-4">Start Date</h3>
        <input
          type="date"
          placeholder="YYYY-MM-DD"
          v-model="hotspot_retailer_sales_from"
        />
      </div>

      <div class="flex p-4 border-2">
        <h3 class="mx-4">Start Date</h3>
        <input
          type="date"
          placeholder="YYYY-MM-DD"
          v-model="hotspot_retailer_sales_to"
        />
      </div>

      <button
        class="flex p-4 border-2 bg-green-500 hover:bg-green-700 text-white"
        @click="hotspot_retailer_sales_break_down"
      >
        Search
      </button>
    </div>

    <div class="flex p-4 mt-5 mb-5">
      <loading v-if="hotspot_retailer_sales_loading"></loading>
      <div v-if="hotspot_retailer_sales_users.length > 0" class="w-full mt-10">
        <!--        Download users-->
        <button
          class="w-full p-4 bg-green-500 mt-10 text-white"
          @click="
            downloadCSVFromJson(
              `hotspot_${hotspot_retailer_sales_country}_${hotspot_retailer_sales_from}_${hotspot_retailer_sales_to}_${hotspot_retailer_sales_order}_retailer_sales_breakdown.csv`,
              hotspot_retailer_sales_users
            )
          "
        >
          Export {{ hotspot_retailer_sales_country }} Retailer Sales Report ,
          From {{ hotspot_retailer_sales_from }} To
          {{ hotspot_retailer_sales_to }}
        </button>
      </div>
      <div v-else>No record(s) found</div>
    </div>

    <!--    End of Retailer Sales BreakDown -->
  </div>
</template>

<script>
import Loading from "@/components/uicomponents/loader/loading";
import axios from "axios";
import * as https from "https";
import querystring from "querystring";
export default {
  name: "RdeAnalytics",
  components: { Loading },
  created() {
    this.from = new Date().toISOString().slice(0, 10);
    this.to = new Date().toISOString().slice(0, 10);
    this.hotspot_topusers_from = new Date().toISOString().slice(0, 10);
    this.hotspot_topusers_to = new Date().toISOString().slice(0, 10);
    this.hotspot_retailer_sales_from = new Date().toISOString().slice(0, 10);
    this.hotspot_retailer_sales_to = new Date().toISOString().slice(0, 10);
  },
  data() {
    return {
      from: "",
      to: "",
      plan_type: "unlimited",
      currency: "NGN",
      loading: false,
      sales_data: [],
      total_sales_data: 0,
      hotspot_country: "Nigeria",
      hotspot_plan_type: "unlimited",
      hotspot_category: "active",
      hotspot_loading: false,
      hotspot_users: [],
      hotspot_data: [],
      hotspot_topusers_loading: false,
      hotspot_topusers: [],
      hotspot_topusers_country: "Nigeria",
      hotspot_topusers_order: "desc",
      hotspot_topusers_from: "",
      hotspot_topusers_to: "",
      hotspot_topusers_total_users: 0,
      hotspot_retailer_sales_country: "Nigeria",
      hotspot_retailer_sales_order: "desc",
      hotspot_retailer_sales_from: "",
      hotspot_retailer_sales_to: "",
      hotspot_retailer_sales_users: [],
      hotspot_retailer_sales_loading: false,
    };
  },
  methods: {
    downloadCSVFromJson: function (filename, data) {
      // convert JSON to CSV
      const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
      const header = Object.keys(data[0]);
      let csv = data.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(",")
      );
      csv.unshift(header.join(","));
      csv = csv.join("\r\n");

      // Create link and download
      var link = document.createElement("a");
      link.setAttribute(
        "href",
        "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csv)
      );
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    hotspot_retailer_sales_break_down: function () {
      let vm = this;
      vm.hotspot_retailer_sales_loading = true;
      vm.hotspot_retailer_sales_users = [];
      var querystring = require("querystring");
      axios
        .post(
          "https://legacy.tizeti.com/geniatechapi/index.php",
          querystring.stringify({
            action: "hotspot_total_sales_by_retailers",
            country: vm.hotspot_retailer_sales_country,
            from: vm.hotspot_retailer_sales_from,
            to: vm.hotspot_retailer_sales_to,
            order: vm.hotspot_retailer_sales_order,
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then(function (response) {
          vm.hotspot_retailer_sales_loading = false;
          vm.hotspot_retailer_sales_users = response.data.users;
          // console.log("hotspot_expiration_break_down response",response.data);
        })
        .catch(function (error) {
          vm.hotspot_retailer_sales_loading = false;
          console.error(error);
        });
    },
    hotspot_topusers_break_down: function () {
      let vm = this;
      vm.hotspot_topusers_loading = true;
      vm.hotspot_topusers = [];
      var querystring = require("querystring");
      axios
        .post(
          "https://legacy.tizeti.com/geniatechapi/index.php",
          querystring.stringify({
            action: "hotspot_top_users",
            country: vm.hotspot_topusers_country,
            from: vm.hotspot_topusers_from,
            to: vm.hotspot_topusers_to,
            order: vm.hotspot_topusers_order,
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then(function (response) {
          vm.hotspot_topusers_loading = false;
          vm.hotspot_topusers_total_users = response.data.total_users;
          vm.hotspot_topusers = response.data.users;
          // console.log("hotspot_expiration_break_down response",response.data);
        })
        .catch(function (error) {
          vm.hotspot_topusers_loading = false;
          console.error(error);
        });
    },
    hotspot_expiration_break_down: function () {
      let vm = this;
      vm.hotspot_loading = true;
      vm.hotspot_users = [];

      var querystring = require("querystring");
      //...
      axios
        .post(
          "https://legacy.tizeti.com/geniatechapi/index.php",
          querystring.stringify({
            action: "hotspot_expiration_tracker",
            plan_type: vm.hotspot_plan_type,
            country: vm.hotspot_country,
            category: vm.hotspot_category,
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then(function (response) {
          vm.hotspot_loading = false;
          vm.hotspot_data = response.data;
          vm.hotspot_users = response.data.users;
          console.log("hotspot_expiration_break_down response", response.data);
        })
        .catch(function (error) {
          vm.hotspot_loading = false;
          console.error(error);
        });
    },
    hotspot_sales_break_down: function () {
      let vm = this;
      vm.total_sales_data = 0;
      vm.loading = true;
      const options = {
        method: "POST",
        url: "https://hotspot.tizetiwifi.com/api/sales_analytics",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          plan_type: vm.plan_type,
          currency: vm.currency,
          from: vm.from,
          to: vm.to,
        },
      };

      axios
        .request(options)
        .then(function (response) {
          //console.log(response.data.data);
          vm.loading = false;
          vm.sales_data = response.data.data;
          vm.sales_data.map((item) => {
            console.log("sum of item ", item.amount);
            vm.total_sales_data =
              parseFloat(vm.total_sales_data) + parseFloat(item.amount);
          });
        })
        .catch(function (error) {
          vm.loading = false;
          alert("An error occurred, please try again later");
          console.error(error);
        });
    },
  },
};
</script>

<style scoped></style>
