<template>
  <div class="bg-white shadow-2xl w-full p-5">
    <input
      class="mb-5 w-full border-2 rounded-2xl p-4"
      type="text"
      v-model="search"
      placeholder="Search Table"
    />

    <table class="w-full p-5 bg-white">
      <tr>
        <th
          class="border-2 p-2 text-left"
          v-for="(column, index) in columns"
          :key="index"
          @click="sortRecords(index)"
        >
          {{ column }}
        </th>
      </tr>
      <tbody>
        <tr v-for="(val, i) in rows" :key="i">
          <td class="p-5 border-2">{{ val[0] }}</td>
          <td class="p-5 border-2">{{ val[1] }}</td>
          <td class="p-5 border-2">{{ val[2] }}</td>
          <td class="p-5 border-2">{{ val[3] }}</td>
          <td class="p-5 border-2">{{ val[4] }}</td>
          <td class="p-5 border-2">{{ val[5] }}</td>
          <td class="p-5 border-2">{{ val[6] }}</td>
          <td class="p-5 border-2">{{ val[7] }}</td>
          <td class="p-5 border-2">
            <button
              class="hover:text-green-500"
              v-if="
                account_type === 'junior_administrator' ||
                account_type === 'super_administrator'
              "
              @click="
                triggerRetailerWalletRecharge(val[5], val[8], val[3], val[2])
              "
            >
              <i class="fas fa-money-check-alt"></i>
            </button>
          </td>
          <td class="p-5 border-2">
            <label
              class="switch"
              v-if="
                account_type === 'junior_administrator' ||
                account_type === 'super_administrator'
              "
            >
              <input
                type="checkbox"
                :checked="val[7] === 'Active'"
                @change="enable_disable(val[8], $event.target.checked)"
              />
              <span class="slider round"></span>
            </label>
          </td>
          <td class="p-5 border-2">
            <i
              class="fas fa-trash-alt"
              @click="decommission_list_event(val[8], val[2], val[0])"
            ></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "RetailerListTable",
  props: ["rowRaws", "account_type"],
  emits: ["decommission_list_event"],
  mounted() {
    this.rows = [...this.rowRaws];
  },
  components: {},
  data() {
    return {
      show_retailer_to_decommission: true,
      search: "",
      columns: [
        "Full-Name",
        "Email",
        "Mobile",
        "Business Name",
        "Address",
        "Country",
        "Wallet Balance",
        "Account Status",
        "Recharge Wallet",
        "Enable/Disable Account",
        "Move Decommission List ",
      ],
      row: [],
      rows: [],
      sortIndex: null,
      sortDirection: null,
    };
  },
  watch: {
    search: function (newvalue, oldvalue) {
      this.onSearch();
    },
  },
  methods: {
    decommission_list_event: function (retailer_id, mobile, name) {
      // console.log("decommission_list_event", retailer_id)
      this.$emit("decommission_list_event", {
        retailer_id: retailer_id,
        mobile: mobile,
        name: name,
      });
    },
    triggerRetailerWalletRecharge: function (
      country,
      user_id,
      business_name,
      mobile
    ) {
      this.$emit("rechargeRetailer", {
        country: country,
        user_id: user_id,
        business_name: business_name,
        mobile: mobile,
      });
    },
    enable_disable(user_id, status) {
      // console.log('enable_disable triggerred ' , `${JSON.stringify(userid)} - ${status}`)
      this.$store.dispatch("admins/action_enable_disable_retailer_account", {
        user_id: user_id,
        status: status,
        toast: this.$toast,
        router: this.$router,
      });
    },
    sortRecords: function (index) {
      if (this.sortDirection == null) {
        this.sortDirection = "asc";
      }
      this.sortIndex = index;
      this.rows = this.rows.sort((rowA, rowB) => {
        if (typeof rowA[index] == "number" || typeof rowB[index] == "number") {
          // console.log('number sortting')
          if (this.sortDirection === "asc" || this.sortDirection == null) {
            this.sortDirection = "desc";
            return rowA[index] - rowB[index];
          } else {
            this.sortDirection = "asc";
            return rowB[index] - rowA[index];
          }
          // return rowA[index].localeCompare(rowB[index], undefined, {'numeric': true})
        } else {
          return rowA[index].localeCompare(rowB[index]);
        }
      });
    },
    onSearch: function () {
      let term = this.search;
      let temp = this.rowRaws;
      this.rows = temp.filter((row) => row.join(" ").includes(term));
    },
  },
};
</script>

<style scoped>
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
