<template>
  <div class="flex flex-col p-5">
    <h1
      class="text-expressgray text-2xl text-center xl:text-left xl:ml-10 font-semibold font-serif"
    >
      Products and Prices
    </h1>
    <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 mt-5 mb-5">
      <div v-for="product in products" :key="product.id">
        <div class="flex flex-col p-8 bg-white rounded-2xl shadow-2xl h-full">
          <h2 class="text-base mb-6">{{ product.name }}</h2>
          <div class="flex justify-between items-center mt-4 text-sm">
            <span>Category: {{ product.category }} </span>
            <span class="text-green-500 text-lg font-bold"
              >{{ product.amount }} {{ product.currency }}</span
            >
          </div>
        </div>

        <!--  -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Products",
  created() {
    console.log("product user", this.user);
    let currency = null;
    if (this.user.country === "Nigeria") {
      currency = "NGN";
    } else if (this.user.country === "CIV") {
      currency = "XOF";
    } else {
      currency = "GHC";
    }

    this.$store.dispatch("products/action_products", {
      currency: currency,
      toast: this.$toast,
      router: this.$router,
    });

    console.log("products", this.products);
  },
  computed: {
    ...mapGetters({
      products: "products/products",
      user: "authentication/getUser",
    }),
  },
};
</script>
<style scoped></style>
