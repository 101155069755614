<template>
    <div class="w-full bg-white shadow-lg px-5 pt-8 pb-10">
      <h1
        class="text-xl text-center lg:pl-10 capitalize lg:text-left mt-5 font-semibold font-serif"
      >
       Customer to Retailer Data Transfer
      </h1>
  
      <form action="" class="mt-8 w-full flex flex-col items-center">
        <div class="grid md:grid-cols-2 xl:grid-cols-3 gap-5 w-full">
          <div class="flex flex-col md:flex-row md:items-center md:space-x-4">
            <label for="from" class="font-semibold mb-2 sm:mb-0">From:</label>
  
            <input
              type="text"
              name="from"
              id="from"
              v-model="fromMac"
              placeholder="Mac ID"
              class="outline-none bg-white h-10 shadow-md border rounded-lg w-full px-3"
            />
          </div>
  
          <div class="flex flex-col md:flex-row md:items-center md:space-x-4">
            <label for="to" class="font-semibold mb-2 sm:mb-0">To:</label>
  
            <input
              type="text"
              name="phone"
              id="phone"
              v-model="phoneNo"
              placeholder="Phone no"
              class="outline-none bg-white h-10 shadow-md border rounded-lg w-full px-3"
            />
          </div>
          <div class="flex flex-col md:flex-row md:items-center md:space-x-4">
            <label for="currency" class="font-semibold mb-2 sm:mb-0"
              >Currency:</label
            >
  
            <select
              name="currency"
              id="currency"
              v-model="currency"
              class="outline-none bg-white h-10 shadow-md border rounded-lg w-full"
            >
              <option value="NGN">Naira</option>
              <option value="GHC">Cedis</option>
              <option value="XOF">XOF</option>
            </select>
          </div>
          <div class="flex flex-col md:flex-row md:items-center md:space-x-4">
            <label for="plan" class="font-semibold mb-2 sm:mb-0">Plan:</label>
  
            <select
              name="plan"
              id="plan"
              v-model="selected_plan"
              class="outline-none bg-white h-10 shadow-md border rounded-lg w-full"
            >
              <option v-if="isLoading" value="">Loading...</option>
              <option
                v-else
                v-for="product in [...plans]"
                v-bind:value="[product.uid, product.amount, product.name]"
                :key="product.uid"
                class="p-2 text-expressgray"
              >
                {{ product.name }}
              </option>
            </select>
          </div>

          <!-- <div class="flex flex-col md:flex-row md:items-center md:space-x-4">
            <label for="from" class="font-semibold mb-2 sm:mb-0">Amount:</label>
  
            <input
              type="number"
              name="amount"
              id="amount"
              v-model="creditAmount"
              placeholder="amount"
              class="outline-none bg-white h-10 shadow-md border rounded-lg w-full px-3"
            />
          </div> -->
        </div>
  
        <input
          type="submit"
          name="transfer"
          id="transfer"
          @click.prevent="submit"
          value="Transfer"
          :disabled="!buttonDisabled"
          class="mt-11 rounded-lg saturate-50 text-white h-11 w-56 mx-auto"
          :class="
            !buttonDisabled
              ? 'bg-green-400 cursor-not-allowed'
              : 'bg-green-600 cursor-pointer'
          "
        />
      </form>
  
      <div v-if="isTransferLoading" class="mt-5 mx-3">
        <loading message=""></loading>
      </div>
    </div>
  </template>
  
  <script setup>
  import { onMounted, watch } from "vue";
  import { computed, ref } from "vue";
  import { useToast } from "vue-toast-notification";
  import { useStore } from "vuex";
  import { useRouter } from "vue-router";
  import { isValidMACAddress } from "@/utils/globalFunctions.js";
  
  const store = useStore();
  const $toast = useToast();
  const $router = useRouter();
  
  const currency = ref("NGN");
  const selected_plan = ref([]);
  const isLoading = ref(false);
  const isTransferLoading = ref(false);
  const fromMac = ref("");
  const phoneNo = ref("");
  const creditAmount = ref(0);
  const plans = computed(() => store.getters["products/products"]);
  
  const buttonDisabled = computed(() => {
    console.log(creditAmount.value);
    
    return (
      (currency.value === "NGN" || currency.value === "GHC" || currency.value === "XOF") &&
      fromMac.value.length === 17 &&
      phoneNo.value.length >= 10 &&
      selected_plan.value.length > 0
    );
  });
  onMounted(async () => {
    await fetchPlans(currency.value);
  });
  
  watch(currency, async (newCurrency) => {
    selected_plan.value = [];
    await fetchPlans(newCurrency);
  });
  
  async function fetchPlans(curr) {
    isLoading.value = true;
  
    await store.dispatch("products/action_products", {
      currency: curr,
      toast: $toast,
      router: $router,
    });
  
    isLoading.value = false;
  }
  
  const submit = async () => {
    if (!isValidMACAddress(fromMac.value)) {
      $toast.error("Please provide valid Mac ID", {
        position: "top-right",
        duration: 4500,
      });
      return;
    }
  
    if (phoneNo.value.length < 10) {
      $toast.error("Check phone number validity", {
        position: "top-right",
        duration: 4500,
      });
      return;
    }

    // if (creditAmount.value.length <= 0) {
    //   $toast.error("Check amount validity", {
    //     position: "top-right",
    //     duration: 4500,
    //   });
    //   return;
    // }

  
    isTransferLoading.value = true;
  
    const amount = parseFloat(selected_plan.value[1]);
    let datapack = 0;
    let duration = 1;
    console.log("amount", amount);
    console.log("amount type", typeof amount);
  
    if (amount === 50 || amount === 0.8) {
      datapack = 104857600;
      duration = 1;
      //  Daily Capped 100mb Plan
    } else if (amount === 100 || amount === 1.5) {
      //  datapack = 314572800
      datapack = 367001600;
      duration = 1;
      //   Daily Capped 300mb Plan
    } else if (amount === 300 || amount === 5) {
      datapack = 777;
      duration = 2;
      //  Daily Unlimited Plan
    } else if (amount === 1000 || amount === 13) {
      datapack = 777;
      duration = 3;
      //  3 Days Daily Unlimited Plan
    } else if (amount === 1500 || amount === 25) {
      datapack = 777;
      duration = 7;
      //  7 Days Daily Unlimited Plan
    } else if (amount === 3000 || amount === 45) {
      datapack = 777;
      duration = 14;
      //  14 Days Daily Unlimited Plan
    } else if (amount === 6000 || amount === 80) {
      datapack = 777;
      duration = 30;
    }
  
    await store.dispatch("admins/transferCreditFromCustomerToRetailer", {
      duration,
      datapack,
      from: fromMac.value,
      phone: phoneNo.value,
      amount: amount,
      toast: $toast,
      router: $router,
    });
    isTransferLoading.value = false;
  
    phoneNo.value = "";
  };
  </script>
  